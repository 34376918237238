$(document).ready(function () {
    var dropdown = $('#smog-in-city');
    var katoId = 6;
    getData = function (selected) {
        $('#air-quality').text('Indeks jakości powietrza: ');
        $('#air-quality-url').text(selected[0].airQuality);
        $('#air-quality-url').attr("href",selected[0].airQualityUrl);
        if(selected[0].isPulmonaryDanger){
            $('#PulmonaryDangerUrl').attr("href",selected[0].pulmonaryUrl);
            $('#PulmonaryDangerImg').attr("src", '/img/custom/pulmonary2.png');
            $('#PulmonaryDangerImg').attr("alt", 'Zagrożenie pulmonologiczne');
            $('#PulmonaryDangerTxt').text(' Pulmonologiczne');
        }
        if(selected[0].isPediatricDanger){
            $('#PediatricDangerUrl').attr("href",selected[0].pediatricUrl);
            $('#PediatricDangerImg').attr("src", '/img/custom/pediatric2.png');
            $('#PediatricDangerImg').attr("alt", 'Zagrożenie pediatryczne');
            $('#PediatricDangerTxt').text(' Pediatryczne');
        }
        if(selected[0].isDiabeticDanger){
            $('#DiabeticDangerUrl').attr("href",selected[0].diabeticUrl);
            $('#DiabeticDangerImg').attr("src", '/img/custom/diabetic2.png');
            $('#DiabeticDangerImg').attr("alt", 'Zagrożenie diabetologiczne');
            $('#DiabeticDangerTxt').text(' Diabetologiczne');
        }
        if(selected[0].isCardiovascularDanger){
            $('#CardiovascularDangerUrl').attr("href",selected[0].cardiovascularUrl);
            $('#CardiovascularDangerImg').attr("src", '/img/custom/cardiovascular2.png');
            $('#CardiovascularDangerImg').attr("alt", 'Zagrożenie sercowo-naczyniowe');
            $('#CardiovascularDangerTxt').text(' Sercowo-naczyniowe');
        }
    };

    dropdown.empty();
    dropdown.append('<option selected="true" disabled>Wybierz miasto</option>');
    dropdown.prop('selectedIndex', 0);
    var data = {};
    var url = 'https://slaskiesmogstop.pl/api/widget/all';
    $.getJSON(url, function (r) {
        data = r;
        $.each(r, function (key, item) {
            dropdown.append($('<option></option>').attr('value', item.cityId).text(item.city));
        })
        var selected = data.filter(function (obj) {
            return obj.cityId == katoId;
        });
        if (selected.length == 1) {
            $('#smog-in-city option[value="'+katoId+'"]').prop("selected", true);
            getData(selected);
        }
    });

    dropdown.change(function () {
        var value = this.value;
        var selected = data.filter(function (obj) {
            return obj.cityId == value;
        });
        if (selected.length == 1) {
            getData(selected);
        }
    });
});